import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import styled from "styled-components"
import { GlobalRow } from "../../constants/global-styles"

const Text = styled.span`
  font-weight: 600;
`

const Container = styled(GlobalRow)`
  cursor: pointer;
`

export default function SeAll({ text, fontWeight, color, ...restProps }) {
  return (
    <Container {...restProps}>
      <Text style={{ fontWeight, color }}>{text}</Text>
      <FontAwesomeIcon
        style={{ fontSize: 14, marginLeft: 5, fontWeight: 100, fill: color }}
        icon={faArrowRight}
      />
    </Container>
  )
}
